<template>
  <Page
    @cta:click="onAddUser"
    :ctaLabel="'Add user'"
    :title="'Users'"
    class="c-users"
  >
    <template #content>
      <el-table
        :data="paginatedUsers.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template #default="props">
            <div class="c-users__user-info">
              <div>
                <p>Personal Record</p>
                <span>Nationlity: {{ props.row.personal_record ? props.row.personal_record.nationality : '' }}</span><br>
                <span>Current Address: {{ props.row.personal_record ? props.row.personal_record.current_address : '' }}</span><br>
                <span>Gender: {{ props.row.personal_record ? props.row.personal_record.gender ? 'Male' : 'Female' : '' }}</span><br>
                <span>Age: {{ props.row.personal_record ? props.row.personal_record.age : '' }}</span><br>
                <span>Height: {{ props.row.personal_record ? props.row.personal_record.height : '' }}</span><br>
                <span>Mother Tongue: {{ props.row.personal_record ? props.row.personal_record.mother_tongue : '' }}</span>
              </div>
              <div>
                <p>Physical Appearance And Life Style</p>
                <span>Body Type: {{ props.row.physical_app_lifestyle ? props.row.physical_app_lifestyle.body_type : '' }}</span><br>
                <span>Skin Type: {{ props.row.physical_app_lifestyle ? props.row.physical_app_lifestyle.skin_type : '' }}</span><br>
                <span>Food: {{ props.row.physical_app_lifestyle ? props.row.physical_app_lifestyle.food : '' }}</span><br>
                <span>Smoking: {{ props.row.physical_app_lifestyle ? props.row.physical_app_lifestyle.smoking : '' }}</span><br>
                <span>Smoking: {{ props.row.physical_app_lifestyle ? props.row.physical_app_lifestyle.drinking : '' }}</span><br>
              </div>
              <div>
                <p>Education</p>
                <span>Educational Level: {{ props.row.personal_record && props.row.personal_record.educational_level_id ? getEducationLevelById(props.row.personal_record.educational_level_id) ? getEducationLevelById(props.row.personal_record.educational_level_id).name : '' : '' }}</span><br>
                <span>Educational Field: {{ props.row.personal_record && props.row.personal_record.educational_field_id ? getEducationFieldById(props.row.personal_record.educational_field_id) ? getEducationFieldById(props.row.personal_record.educational_field_id).name : '' : '' }}</span>
              </div>
              <div>
                <p>Family Details</p>
                <span>family Status: {{ props.row.family_details ? props.row.family_details.family_status : '' }}</span><br>
                <span>family Type: {{ props.row.family_details ? props.row.family_details.family_type : '' }}</span><br>
                <span>family Number: {{ props.row.family_details ? props.row.family_details.family_number : '' }}</span><br>
                <span>Country Living: {{ props.row.family_details && props.row.family_details.country_living ? 'Nepal' : 'Abroad' }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="ID"
          prop="id"
          width="100"
        >
        </el-table-column>
        <el-table-column
          label="Name"
          prop="name">
        </el-table-column>
        <el-table-column
          label="Email"
          prop="email">
        </el-table-column>
        <el-table-column
          label="Status"
          prop="status">
          <template #default="scope">
            <el-switch
              @click="onStatusChange(scope.row)"
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          align="right">
          <template #header>
            <el-input
              v-model="search"
              size="mini"
              placeholder="Type to search"/>
          </template>
          <template #default="scope">
            <router-link
              :to="{ name: 'edit-user', params: { id: scope.row.id} }"
              class="c-users__edit"
            >
            </router-link>
            <el-button
              @click="openPasswordResetPopup(scope.row.id)"
              size="mini"
              type="primary"
            ><i class="el-icon-refresh-left"></i>
            </el-button>

            <router-link
              :to="{ name: 'edit-user', params: { id: scope.row.id} }"
              class="c-users__edit"
            >
              <el-button
                size="mini"
                type="success">
                <i class="el-icon-edit"></i>
              </el-button>
            </router-link>

            <el-button
              @click="openUserDeletePopup(scope.row.id)"
              size="mini"
              type="warning"
            ><i class="el-icon-delete"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="c-users__pagination">
        <el-pagination
          @next-click="onNextPage"
          @prev-click="onPrevPage"
          @current-change="onPageClick"
          :total="users && users.length"
          :page-size="userSize"
          background
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>

      <ResetPasswordPopup
        @action:confirm="onPasswordResetSubmit"
        @popup:close="closePasswordResetPopup"
        :showPopup="showResetPasswordPopup"
        :title="'Reset Password'"
        v-if="showResetPasswordPopup"
      >
        <template #content>
          <el-form>
            <el-form-item label="Password">
              <el-input
                type="password"
                v-model="password"
              ></el-input>
            </el-form-item>

            <el-form-item label="Confirm Password">
              <el-input
                type="password"
                v-model="confirm_password"
              ></el-input>
            </el-form-item>

            <span
              class="c-users__error"
              v-if="showPasswordError"
            >
              Confirm Password does not match
            </span>
          </el-form>
        </template>
      </ResetPasswordPopup>

      <DeleteUserPopup
        @action:confirm="onUserDelete"
        @popup:close="closeUserDeletePopup"
        :showPopup="showUserDeletePopup"
        :title="'Are you sure you want to delete this user?'"
        v-if="showUserDeletePopup"
      >
        <template #content>
        </template>
      </DeleteUserPopup>
    </template>
  </Page>
</template>

<script>
import Page from '@/components/Page'
import Popup from '@/components/Popup'

import userMixin from '@/mixins/user'

export default {
  components: {
    Page,
    ResetPasswordPopup: Popup,
    DeleteUserPopup: Popup
  },

  computed: {
    getUsersData () {
      return this.users && this.users.map(user => {
        return {
          id: user.id,
          name: user.name,
          email: user.email,
          status: user.status
        }
      })
    },

    paginatedUsers () {
      const start = (this.userPageNumber - 1) * this.userSize
      const end = start + this.userSize
      return this.users ? this.users.slice(start, end) : null
    }
  },

  created () {
    this.getUsers()
    this.getEducationLevels()
    this.getEducationFields()
  },

  data () {
    return {
      search: '',
      userPageNumber: 1,
      userSize: 10,
      userId: null,
      password: null,
      confirm_password: null,
      showUserDeletePopup: false,
      showResetPasswordPopup: false,
      showPasswordError: false
    }
  },

  methods: {
    onNextPage () {
      this.userPageNumber++
    },

    onPrevPage () {
      this.userPageNumber--
    },

    onAddUser () {
      this.$router.push({ name: 'add-user' })
    },

    onPageClick (pageNumber) {
      this.userPageNumber = pageNumber
    },

    onStatusChange (user) {
      this.toggleStatus(user.id).then(() => {
        if (user.status) {
          this.$message({
            type: 'success',
            message: 'User status activated successfully.'
          })
        } else {
          this.$message({
            type: 'warning',
            message: 'User status disabled successfully.'
          })
        }

        this.getUsers()
      })
    },

    openUserDeletePopup (userId) {
      this.userId = userId
      this.showUserDeletePopup = true
    },

    closeUserDeletePopup () {
      this.showUserDeletePopup = false
    },

    openPasswordResetPopup (userId) {
      this.userId = userId
      this.showResetPasswordPopup = true
    },

    closePasswordResetPopup () {
      this.showResetPasswordPopup = false
    },

    onUserDelete () {
      this.deleteUser({
        id: this.userId
      }).then(() => {
        this.$message({
          type: 'success',
          message: 'User deleted successfully.'
        })

        this.userId = null
        this.showUserDeletePopup = false
        this.getUsers()
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: 'Ops! Something went wrong.'
        })

        this.showUserDeletePopup = false
      })
    },

    onPasswordResetSubmit () {
      if (this.password !== this.confirm_password) {
        this.showPasswordError = true
      } else {
        this.showPasswordError = false

        this.resetUserPassword({
          user_id: this.userId,
          password: this.password
        }).then(() => {
          this.$message({
            type: 'success',
            message: 'User password changed successfully.'
          })

          this.userId = null
          this.showResetPasswordPopup = false
        }).catch(() => {
          this.$message({
            type: 'warning',
            message: 'Ops! Something went wrong.'
          })

          this.userId = null
          this.showResetPasswordPopup = false
        })
      }
    }
  },

  mixins: [userMixin]
}
</script>

<style lang="scss">
.c-users {
  &__edit {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  &__error {
    color: darkred;
  }

  &__pagination {
    margin-top: 1rem;
  }

  &__user-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
